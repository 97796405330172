import React from 'react'
import styled from '@emotion/styled'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator'
import { fonts } from '@/utils/preset'

const LinkButton1 = props => {
  const { theme, type, scale, classes, children, className, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="contained"
      classes={classes}
      {...other}
    >
      { children }
      <Arrow>
        <svg width="50" height="12" viewBox="0 0 50 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Arrow_Cap d="M50 6L44 0V12L50 6Z" fill="white"/>
          <Arrow_Line d="M40 6L2 6" stroke="white"/>
        </svg>
      </Arrow>
    </MuiButton>
  )
}

const getStyles = (type, scale, theme) => {
  if (type === 'contrast') {
    return {
      backgroundColor: theme.background.contrast,
      color: theme.text.contrast,
      hoverBackgroundColor: lighten(theme.background.contrast, 0.2)
    }
  } else if (scale) {
    return {
      backgroundColor: theme.scale[scale],
      color: theme.scale.contrast[scale],
      hoverBackgroundColor: theme.scale.hover[scale]
    }
  } else {
    return {
      backgroundColor: theme.background.default,
      color: theme.text.default,
      hoverBackgroundColor: darken(theme.background.default, 0.1)
    }
  }
}

const styles = props => {
  const styles = getStyles(props.type, props.scale, props.theme)
  return {
    root: {
      backgroundColor: styles.backgroundColor,
      color: styles.color,
      height: 64,
      padding: '0 16px',
      fontSize: 14,
      fontFamily: fonts.base,
      boxShadow: 'none',
      justifyContent: 'flex-start',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: 'none',
        '@media (hover: none)': {
          backgroundColor: styles.backgroundColor
        },
        '&:disabled': {
          backgroundColor: styles.backgroundColor
        }
      },
      '&:disabled': {
        backgroundColor: styles.backgroundColor,
        color: styles.color,
        opacity: 0.6
      }
    },
    label: {
      position: 'relative',
      paddingRight: 66
    }
  }
}

const Arrow = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

const Arrow_Cap = styled.path`
  fill: currentColor;
`

const Arrow_Line = styled.path`
  stroke: currentColor;
`

export default ThemeConsumer(WithStylesProps(styles)(LinkButton1))
